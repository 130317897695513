

























import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';
import { RolesService } from '@/shared/services/roles/roles.service';
import store from '@/store/index';

@Component({
  components: { TmsTable }
})
export default class Inventory extends Vue {
  columns: TableHeaders[] = null;
  categories = null;
  RolesService = RolesService;
  rows = [];
  loading = true;

  PermissionsToAreaObject = {};

  created() {
    this.getRoles();

    store.watch(
      (state: any) => state.division.division,
      () => this.getRoles()
    );
  }

  async getRoles(limit?) {
    this.loading = true;
    const {
      table,
      UserRoleCategoriesHeaders,
      UserRoleHeaders,
      PermissionsToAreaObject
    } = await this.RolesService.getRolesData();
    this.rows = table;
    this.columns = UserRoleHeaders;
    this.categories = UserRoleCategoriesHeaders;
    this.PermissionsToAreaObject = PermissionsToAreaObject;

    this.loading = false;
  }

  newRole() {
    const data = { role: '' };

    Object.entries(this.PermissionsToAreaObject).map(
      ([key, valaue]) => (data[key] = (valaue as any).action)
    );
    this.rows.push(data);
  }

  async onAction({ key, data }) {
    if (key !== 'update') {
      return;
    }
    data.id
      ? this.RolesService.update(data, this.PermissionsToAreaObject)
      : this.RolesService.save(data, this.PermissionsToAreaObject);
  }
}
